@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

$font-1: 'Sora', sans-serif;
$font-2: 'Caveat', cursive;

$dark: #1a2444;
$light: rgba(255, 255, 255, 1);
$accent: #f6a637;
$blue:#05a5a5;

$light1:#c4e8e8;

$dt-5: rgba(0, 0, 0, .05);
$dt-10: rgba(0, 0, 0, .1);
$dt-20: rgba(0, 0, 0, .2);
$dt-30: rgba(0, 0, 0, .3);
$dt-40: rgba(0, 0, 0, .4);
$dt-50: rgba(0, 0, 0, .5);
$dt-60: rgba(0, 0, 0, .6);
$dt-70: rgba(0, 0, 0, .7);
$dt-80: rgba(0, 0, 0, .8);
$dt-90: rgba(0, 0, 0, .9);
$dt-95: rgba(0, 0, 0, .95);

$lt-5: rgba(255, 255, 255, .05);
$lt-10: rgba(255, 255, 255, .1);
$lt-20: rgba(255, 255, 255, .2);
$lt-30: rgba(255, 255, 255, .3);
$lt-40: rgba(255, 255, 255, .4);
$lt-50: rgba(255, 255, 255, .5);
$lt-60: rgba(255, 255, 255, .6);
$lt-70: rgba(255, 255, 255, .7);
$lt-80: rgba(255, 255, 255, .8);
$lt-90: rgba(255, 255, 255, .9);
$lt-95: rgba(255, 255, 255, .95);

$gs-5: rgba(242, 242, 242, 1);
$gs-10: rgba(229, 229, 229, 1);
$gs-20: rgba(204, 204, 204, 1);
$gs-30: rgba(178, 178, 178, 1);
$gs-40: rgba(153, 153, 153, 1);
$gs-50: rgba(128, 128, 128, 1);
$gs-60: rgba(102, 102, 102, 1);
$gs-70: rgba(77, 77, 77, 1);
$gs-80: rgba(51, 51, 51, 1);
$gs-90: rgba(26, 26, 26, 1);
$gs-95: rgba(13, 13, 13, 1);

$h1: 60px;
$h2: 46px;
$h3: 26px;
$h4: 22px;
$h5: 18px;
$h6: 15px;

$text: 15px;

$t-sm: .2s cubic-bezier(0, 0, 0.3642, 1);
$t-md: .4s cubic-bezier(0, 0, 0.3642, 1);
$t-lg: .6s cubic-bezier(0, 0, 0.3642, 1);

:export {
    accentColor: $accent;
    darkColor: $dark;
    lightColor: $light;
}