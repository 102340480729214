
/* image popup */
img.mfp-img {
    box-shadow: 0 0 8px rgb(0 0 0 / 60%);
    position: absolute;
    max-height: 100%;
    padding: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mfp-img-container .mfp-close {
    top: -110px;
    right: -24px;
}

/* swiper container hidden */
.mil-wrapper .swiper-container {
    overflow: visible;
}