/* -------------------------------------------

Name: 		Ruizarch
Version:    1.1
Author: bslthemes
Author URL: https://themeforest.net/user/bslthemes/portfolio
Developer:	Nazar Miller (millerDigitalDesign), email: miller.themes@gmail.com

------------------------------------------- */

/*--------------------------------------------

1. common
    - main
    - typography
    - suptitle
    - link
    - button
    - breadcrumbs
    - list
    - backgrounds
    - spaces
    - 
2. components
    - scrollbar
    - background grid
    - banner
    - slider navigation
    - counters
    - illustrations
    - icons
    - services
    - portfolio
    - accordion
    - blog
    - footer
    - top panel
    - menu button
    - hover images
    - process
    - inputs
    - reviews
    - price
    - skills
    - call to action
    - sidebar
    - map
    - team
    - comments
    - filter
    - timer
    - load more

--------------------------------------------*/

@import 'variables.module';
@import 'common';
@import 'components';

body{
    background-color: #fdf8f0;
}

.center-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

.banner-text{
    position: absolute;
    bottom: 0px;
    left: 20px;
}

.footer-img{
    width: 100%;
    height: 100%;
  object-fit: contain;
  opacity: 1.0;
}

.detail-header-pannel{
    margin: 10px 10px 0 10px;
    display: flex;
    justify-content: space-between;
}

.download-btn{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $accent;
     background-color: #fff;
    border-radius: 10px;
    color: $dark;
    padding: 0 20px;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600;
    transition: $t-sm;
    font-size: 15px;
}

.pdf-icon{
   height: 20px;
   margin-right: 10px;
}

.social-media-list{
    display: flex;
    flex-direction: row;
    align-items: center;

    & .socila-media-icon{
        margin-right: 5px;
    }
}
